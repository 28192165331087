import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { PageConsult, PageConsultInput, ServiceConsulta } from "./Services";
import { User } from "./UserService";

let instance: SaqueService;

export type SaqueStatus =
  | "solicitado"
  | "processando"
  | "cancelado"
  | "concluído";

export const saqueStatus: Readonly<SaqueStatus[]> = Object.freeze([
  "solicitado",
  "processando",
  "cancelado",
  "concluído",
]);

export interface Saque {
  id: number;
  createdAt?: Date;
  updatedAt?: Date;
  valor: number;
  taxa: number;
  status: string;
  chavePix: string;
  info?: any;
  user?: User;
  saldoAnterior?: number;
  saldo?: number;
}

export interface SolicitarSaqueInput {
  valor: number;
  chavePix: string;
  senhaFinanceira: string;
  code: string;
}

export const emptySolicitarSaque: Readonly<SolicitarSaqueInput> = Object.freeze(
  {
    valor: null!,
    chavePix: null!,
    senhaFinanceira: null!,
    code: null!,
  },
);

export function getService(): SaqueService {
  if (!instance) {
    instance = new SaqueService();
  }
  return instance;
}

export default class SaqueService implements ServiceConsulta<Saque> {
  async consultar(
    variables: PageConsultInput,
  ): Promise<PageConsult<Saque>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query getUsers(
            $q: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
          ) {
            me {
              saques(
                q: $q
                first: $first
                page: $page
                orderBy: {
                  column: $sortField
                  order: $sortOrder
                }
              ) {
                data {
                  id
                  createdAt
                  valor
                  taxa
                  status
                  chavePix
                }
                paginatorInfo {
                  count
                  currentPage
                  firstItem
                  hasMorePages
                  lastItem
                  lastPage
                  perPage
                  total
                }
              }
            }
          }
        `,
      });
    const { data: { me: { saques } } } = result;
    return saques;
  }

  async solicitarSaque(input: SolicitarSaqueInput): Promise<Saque> {
    const result = await client
      .mutate({
        variables: {
          input,
        },
        mutation: gql`
          mutation($input: SolicitarSaqueInput!) {
            r: solicitarSaque(input: $input) {
              id
              valor
              taxa
              chavePix
              status
            }
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }
}

export interface SaquePageConsultInput extends PageConsultInput {
  userId?: number;
  status?: string;
}

export class SaqueAdminService implements ServiceConsulta<Saque> {
  async consultar(
    variables: SaquePageConsultInput,
  ): Promise<PageConsult<Saque>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query getUsers(
            $userId: ID
            $q: String
            $status: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
          ) {
            saques(
              userId: $userId
              first: $first
              page: $page
              q: $q
              status: $status
              orderBy: {
                column: $sortField
                order: $sortOrder
              }
            ) {
              data {
                id
                createdAt
                valor
                taxa
                status
                chavePix
                saldo
                saldoAnterior
                user {
                  id
                  code
                  name
                  avatar
                  pessoa {
                    documento
                  }
                }
              }
              paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
              }
            }
          }
        `,
      });
    const { data: { saques } } = result;
    return saques;
  }

  async changeStatus(
    id: number,
    status: string,
  ): Promise<boolean> {
    const result = await client
      .mutate({
        variables: {
          id,
          status,
        },
        mutation: gql`
          mutation($id: ID! $status: String!) {
            r: changeSaqueStatus(id: $id status: $status)
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }
}
