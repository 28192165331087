
import { SaqueAdminService, Saque, saqueStatus } from "@/services/SaqueService";
import { DataTableControl } from "@/services/Services";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useShowUser } from "@/components/UserView.vue";
import Operacoes from "./operacoes.vue";
import { http } from "@/api/rest";
import useClipboard from "vue-clipboard3";
import { useToast } from "primevue/usetoast";

interface SaqueDataTableControl extends DataTableControl {
  status: string;
}

interface Operacoes {
  visible: boolean;
  saque: Saque;
}

export default defineComponent({
  components: { Operacoes },
  data() {
    return {
      cadastroVisible: false,
      deleteItemDialog: false,
      item: {},
      selectedItens: null,
      submitted: false,
    };
  },
  setup() {
    const data = ref<Saque[]>([]);
    const loading = ref(false);
    const dataParams = reactive<SaqueDataTableControl>({
      rows: 10,
      page: 0,
      sortField: "e.id",
      sortOrder: -1,
      totalRecords: 0,
      filter: "",
      status: null!,
    });

    async function find() {
      loading.value = true;
      try {
        const result = await new SaqueAdminService().consultar({
          q: dataParams.filter,
          status: dataParams.status,
          first: dataParams.rows,
          page: dataParams.page + 1,
          sortField: dataParams.sortField,
          sortOrder: dataParams.sortOrder > 0 ? "ASC" : "DESC",
        });
        data.value = result.data;
        dataParams.totalRecords = result.paginatorInfo.total;
      } finally {
        loading.value = false;
      }
    }

    const userView = useShowUser();

    const operacoes = reactive<Operacoes>({
      visible: false,
      saque: null!,
    });
    watch(
      () => operacoes.visible,
      (v) => {
        if (!v) {
          operacoes.saque = null!;
        }
      }
    );

    const starting = ref(true);
    const totais = ref<any>();
    async function getTotais() {
      totais.value = (await http.get("api/saques/totais")).data;
    }
    onMounted(async () => {
      try {
        await getTotais();
      } finally {
        starting.value = false;
      }
    });

    const { toClipboard } = useClipboard();
    const toast = useToast();

    return {
      starting,
      totais,
      saqueStatus,
      userView,
      loading,
      dataParams,
      find,
      data,
      operacoes,
      showOperacoes(saque: Saque) {
        operacoes.visible = true;
        operacoes.saque = saque;
      },
      copy(v: string) {
        toClipboard(v);
        toast.add({
          severity: "success",
          summary: "Copiado para área de transferência",
          life: 3000,
        });
      },
      operacaoUpdated() {
        find();
        getTotais();
      },
    };
  },
  mounted() {
    this.find();
  },
  methods: {
    onPage(evt: any) {
      this.dataParams.rows = evt.rows;
      this.dataParams.page = evt.page;
      this.find();
    },
    onSort(evt: any) {
      this.dataParams.sortField = evt.sortField;
      this.dataParams.sortOrder = evt.sortOrder;
      this.find();
    },
  },
});
