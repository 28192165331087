<template>
  <div class="p-grid card" v-if="starting">
    <p-progress-spinner />
  </div>
  <div class="p-grid card" v-else>
    <div class="p-col-12 p-md-4">
      <div class="card-saque">
        <span>Total líquido à pagar:</span>
        <span>
          <b>{{ $formatCurrency(parseFloat(totais?.aPagar) || 0) }}</b>
        </span>
      </div>
    </div>
    <div class="p-col-12 p-md-4">
      <div class="card-saque">
        <span>Saques concluídos:</span>
        <span>
          <b>
            {{
              $formatCurrency(parseFloat(totais?.concluidos) || 0)
            }}
          </b>
        </span>
      </div>
    </div>
    <div class="p-col-12 p-md-4">
      <div class="card-saque">
        <span>Total de taxas recebidas:</span>
        <span>
          <b>{{ $formatCurrency(parseFloat(totais?.taxas) || 0) }}</b>
        </span>
      </div>
    </div>

    <p-toolbar class="p-mb-4 p-col-12">
      <template #left>
        <h5 class="p-m-0">Saques</h5>
      </template>

      <template #right>
        <div class="p-d-inline-flex p-flex-wrap">
          <p-dropdown
            v-model="dataParams.status"
            required
            :options="saqueStatus"
            placeholder="Todas os status"
            showClear
            style="min-width: 200px"
            @change="find"
            class="p-mr-2 p-mb-2"
          />
          <span>
            <p-inputtext
              v-model="dataParams.filter"
              @keypress.enter="find"
              placeholder="Pesquisar..."
            />
            <p-button icon="pi pi-search" @click="find" />
          </span>
        </div>
      </template>
    </p-toolbar>

    <p-datatable
      ref="dt"
      :value="data"
      v-model:selection="selectedItens"
      data-key="id"
      :loading="loading"
      :first="0"
      :lazy="true"
      :paginator="true"
      :rows="dataParams.rows"
      :totalRecords="dataParams.totalRecords"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="{first} - {last} de {totalRecords} registros"
      @page="onPage"
      @sort="onSort"
      responsiveLayout="stack"
      stripedRows
      class="p-col-12"
    >
      <template #empty>
        <div class="p-text-center p-mt-5 p-mb-5">Nenhum registro encontrado!</div>
      </template>

      <p-column field="e.id" header="Nº" :sortable="true" style="width: 70px">
        <template #body="{ data }">{{ data.id }}</template>
      </p-column>
      <p-column field="e.created_at" header="Data" :sortable="true">
        <template
          #body="{ data }"
        >{{ new Date(data.createdAt).toLocaleString() }}</template>
      </p-column>
      <p-column
        field="e.user_id"
        header="Afiliado"
        :sortable="true"
        style="min-width: 300px"
      >
        <template #body="{ data }">
          <div class="p-d-flex p-ai-center">
            <x-avatar
              class="p-mr-2"
              style="
                min-width: 30px;
                width: 30px;
                min-height: 30px;
                height: 30px;
              "
              :user="data.user"
            />
            <a style="cursor: pointer" @click="userView.show(data.user.id)">
              {{
                data.user.name
              }}
            </a>
          </div>
        </template>
      </p-column>
      <p-column header="CPF/CNPJ">
        <template #body="{ data }">
          <b>{{ data.user.pessoa.documento }}</b>
        </template>
      </p-column>
      <p-column field="e.valor" header="Valor Solicitado" :sortable="true">
        <template #body="{ data }">
          <b>{{ $formatCurrency(data.valor) }}</b>
        </template>
      </p-column>
      <p-column field="e.taxa" header="Taxa" :sortable="true">
        <template #body="{ data }">{{ $formatCurrency(data.taxa) }}</template>
      </p-column>
      <p-column field="e.chavePix" header="Forma Pagamento" :sortable="true">
        <template #body="{ data }">
          <div>
            PIX: {{ data.chavePix }}
            <p-button
              v-if="data.status === 'processando'"
              @click="copy(data.chavePix)"
              icon="pi pi-copy"
              class="p-button-secondary p-mr-2"
              title="Copiar para área de transferência"
            />
          </div>
        </template>
      </p-column>
      <p-column header="Valor Líquido">
        <template #body="{ data }">
          <div>
            {{ $formatCurrency(data.valor - data.taxa) }}
            <p-button
              v-if="data.status === 'processando'"
              @click="
              copy(
                $formatCurrency(data.valor - data.taxa).replace(
                  /[^\d,.]/g,
                  ''
                )
              )
              "
              icon="pi pi-copy"
              class="p-button-secondary p-mr-2"
              title="Copiar para área de transferência"
            />
          </div>
        </template>
      </p-column>
      <p-column header="R$ Saldo Pré-Saque">
        <template
          #body="{ data }"
        >{{ $formatCurrency(data.saldoAnterior || 0) }}</template>
      </p-column>
      <p-column header="R$ Saldo Pós-Saque">
        <template #body="{ data }">{{ $formatCurrency(data.saldo || 0) }}</template>
      </p-column>
      <p-column header="Status">
        <template #body="{ data }">
          <b
            @click="showOperacoes(data)"
            style="cursor: pointer"
          >{{ data.status }}</b>
        </template>
      </p-column>
    </p-datatable>
  </div>

  <x-user-view
    v-model:visible="userView.visible"
    :title="userView.title"
    :id="userView.id"
  ></x-user-view>
  <Operacoes
    v-model:visible="operacoes.visible"
    :saque="operacoes.saque"
    @update="operacaoUpdated"
  />
</template>

<script lang="ts">
import { SaqueAdminService, Saque, saqueStatus } from "@/services/SaqueService";
import { DataTableControl } from "@/services/Services";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useShowUser } from "@/components/UserView.vue";
import Operacoes from "./operacoes.vue";
import { http } from "@/api/rest";
import useClipboard from "vue-clipboard3";
import { useToast } from "primevue/usetoast";

interface SaqueDataTableControl extends DataTableControl {
  status: string;
}

interface Operacoes {
  visible: boolean;
  saque: Saque;
}

export default defineComponent({
  components: { Operacoes },
  data() {
    return {
      cadastroVisible: false,
      deleteItemDialog: false,
      item: {},
      selectedItens: null,
      submitted: false,
    };
  },
  setup() {
    const data = ref<Saque[]>([]);
    const loading = ref(false);
    const dataParams = reactive<SaqueDataTableControl>({
      rows: 10,
      page: 0,
      sortField: "e.id",
      sortOrder: -1,
      totalRecords: 0,
      filter: "",
      status: null!,
    });

    async function find() {
      loading.value = true;
      try {
        const result = await new SaqueAdminService().consultar({
          q: dataParams.filter,
          status: dataParams.status,
          first: dataParams.rows,
          page: dataParams.page + 1,
          sortField: dataParams.sortField,
          sortOrder: dataParams.sortOrder > 0 ? "ASC" : "DESC",
        });
        data.value = result.data;
        dataParams.totalRecords = result.paginatorInfo.total;
      } finally {
        loading.value = false;
      }
    }

    const userView = useShowUser();

    const operacoes = reactive<Operacoes>({
      visible: false,
      saque: null!,
    });
    watch(
      () => operacoes.visible,
      (v) => {
        if (!v) {
          operacoes.saque = null!;
        }
      }
    );

    const starting = ref(true);
    const totais = ref<any>();
    async function getTotais() {
      totais.value = (await http.get("api/saques/totais")).data;
    }
    onMounted(async () => {
      try {
        await getTotais();
      } finally {
        starting.value = false;
      }
    });

    const { toClipboard } = useClipboard();
    const toast = useToast();

    return {
      starting,
      totais,
      saqueStatus,
      userView,
      loading,
      dataParams,
      find,
      data,
      operacoes,
      showOperacoes(saque: Saque) {
        operacoes.visible = true;
        operacoes.saque = saque;
      },
      copy(v: string) {
        toClipboard(v);
        toast.add({
          severity: "success",
          summary: "Copiado para área de transferência",
          life: 3000,
        });
      },
      operacaoUpdated() {
        find();
        getTotais();
      },
    };
  },
  mounted() {
    this.find();
  },
  methods: {
    onPage(evt: any) {
      this.dataParams.rows = evt.rows;
      this.dataParams.page = evt.page;
      this.find();
    },
    onSort(evt: any) {
      this.dataParams.sortField = evt.sortField;
      this.dataParams.sortOrder = evt.sortOrder;
      this.find();
    },
  },
});
</script>
