<template>
  <p-dialog
    header="Operações de Saque"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 500px"
  >
    <div v-if="saving" class="p-text-center">
      <p-progress-spinner />
    </div>

    <template v-if="saque && !saving">
      <div class="p-mb-4">Saque #{{ saque.id }} - {{ saque.status }}</div>

      <div>
        <span>
          Valor disponível:
          <p-progress-spinner
            v-if="loadingSaldo"
            style="width: 15px; height: 15px"
          />
          <b v-else> {{ $formatCurrency(saldo) }} </b>
        </span>
      </div>

      <template v-if="saque.status === 'solicitado'">
        <p-button
          label="Processando"
          @click="changeStatus('processando')"
          class="p-mr-2"
        />
        <p-button
          label="Cancelar"
          @click="changeStatus('cancelado')"
          class="p-mr-2"
        />
      </template>
      <template v-if="saque.status === 'processando'">
        <p-button
          label="Concluído"
          @click="changeStatus('concluído')"
          class="p-mr-2"
        />
      </template>
    </template>
  </p-dialog>
</template>

<script lang="ts">
import { getService, User } from "@/services/UserService";
import { SaqueAdminService, Saque } from "@/services/SaqueService";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { defineComponent, PropType, ref, watch } from "vue";

export default defineComponent({
  emits: ["update:visible", "update"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    saque: Object as PropType<Saque>,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const confirm = useConfirm();

    function close() {
      if (saving.value) {
        return;
      }
      emit("update:visible", false);
    }

    async function confirmar(
      message = "Tem certeza que deseja excluir?"
    ): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        confirm.require({
          message,
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          async accept() {
            resolve(true);
          },
          reject() {
            resolve(false);
          },
        });
      });
    }

    const saving = ref(false);
    async function changeStatus(status: string) {
      if (!props.saque) {
        return;
      }
      if (!(await confirmar("Tem certeza?"))) {
        return;
      }
      saving.value = true;
      try {
        await new SaqueAdminService().changeStatus(props.saque.id, status);
        toast.add({
          severity: "success",
          summary: "Status alterado",
          life: 3000,
        });
        emit("update");
        saving.value = false;
        close();
      } finally {
        saving.value = false;
      }
    }

    const saldo = ref(0);
    const loadingSaldo = ref(true);
    async function loadSaldo() {
      if (!props.saque?.user?.id) {
        return;
      }
      loadingSaldo.value = true;
      try {
        const saldos = await getService().getSaldos(props.saque.user.id);
        console.log(saldos);
        saldo.value = parseFloat(saldos.disponivel || "0");
      } finally {
        loadingSaldo.value = false;
      }
    }
    watch(
      () => props.visible,
      (v) => {
        if (v) {
          loadSaldo();
        } else {
          saldo.value = 0;
        }
      }
    );

    return {
      saldo,
      loadingSaldo,
      saving,
      close,
      changeStatus,
      updateDialogVisible(v: boolean) {
        if (!v && saving.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
</script>
